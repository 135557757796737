import api from './api';

interface RegisterData {
    name: string;
    email: string;
    password: string;
    role: string;
    companyId: number;
}

interface LoginData {
    email: string;
    password: string;
}

export const register = async (data: RegisterData) => {
    return api.post('/api/auth/register', data);
};

export const login = async (data: LoginData) => {
    return api.post('/api/auth/login', data);
};

