import React from 'react';
import { Box, Grid, Paper, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Chart from 'react-apexcharts';

const DashboardContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  height: '100%',
}));

const StatAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const Dashboard = () => {
  const mockData = {
    totalAppointments: 120,
    newPatients: 30,
    pendingReports: 5,
    monthlyRevenue: 'R$ 50.000',
  };

  const appointmentsPerMonth = [
    { month: 'Jan', appointments: 30 },
    { month: 'Feb', appointments: 20 },
    { month: 'Mar', appointments: 40 },
    { month: 'Apr', appointments: 50 },
    { month: 'May', appointments: 60 },
    { month: 'Jun', appointments: 70 },
    { month: 'Jul', appointments: 80 },
  ];

  const patientsByAge = [
    { name: '0-18', value: 40 },
    { name: '19-35', value: 30 },
    { name: '36-50', value: 20 },
    { name: '51+', value: 10 },
  ];

  const monthlyRevenue = [
    { month: 'Jan', revenue: 5000 },
    { month: 'Feb', revenue: 7000 },
    { month: 'Mar', revenue: 8000 },
    { month: 'Apr', revenue: 6000 },
    { month: 'May', revenue: 9000 },
    { month: 'Jun', revenue: 12000 },
    { month: 'Jul', revenue: 15000 },
  ];

  const proceduresPerformed = [
    { name: 'Consulta', value: 400 },
    { name: 'Exame de Sangue', value: 300 },
    { name: 'Ultrassonografia', value: 300 },
    { name: 'Raio-X', value: 200 },
  ];

  const newPatientsPerMonth = [
    { month: 'Jan', patients: 5 },
    { month: 'Feb', patients: 10 },
    { month: 'Mar', patients: 15 },
    { month: 'Apr', patients: 20 },
    { month: 'May', patients: 25 },
    { month: 'Jun', patients: 30 },
    { month: 'Jul', patients: 35 },
  ];

  const COLORS = ['#1B998B', '#2D3047', '#FFFD82', '#FF9B71'];

  return (
    <DashboardContainer>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard>
            <Box>
              <Typography variant="h6">Total de Atendimentos</Typography>
              <Typography variant="h4">{mockData.totalAppointments}</Typography>
            </Box>
            <StatAvatar>
              <CalendarTodayIcon />
            </StatAvatar>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard>
            <Box>
              <Typography variant="h6">Novos Pacientes</Typography>
              <Typography variant="h4">{mockData.newPatients}</Typography>
            </Box>
            <StatAvatar>
              <PeopleIcon />
            </StatAvatar>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard>
            <Box>
              <Typography variant="h6">Relatórios Pendentes</Typography>
              <Typography variant="h4">{mockData.pendingReports}</Typography>
            </Box>
            <StatAvatar>
              <AssignmentIcon />
            </StatAvatar>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard>
            <Box>
              <Typography variant="h6">Receita Mensal</Typography>
              <Typography variant="h4">{mockData.monthlyRevenue}</Typography>
            </Box>
            <StatAvatar>
              <AssessmentIcon />
            </StatAvatar>
          </StatCard>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Número de Atendimentos por Mês
            </Typography>
            <Chart
              options={{
                chart: {
                  type: 'line',
                },
                xaxis: {
                  categories: appointmentsPerMonth.map(item => item.month),
                },
                colors: ['#1B998B'],
              }}
              series={[
                {
                  name: 'Atendimentos',
                  data: appointmentsPerMonth.map(item => item.appointments),
                },
              ]}
              type="line"
              height={300}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Distribuição de Pacientes por Faixa Etária
            </Typography>
            <Chart
              options={{
                chart: {
                  type: 'pie',
                },
                labels: patientsByAge.map(item => item.name),
                colors: COLORS,
              }}
              series={patientsByAge.map(item => item.value)}
              type="pie"
              height={300}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Receita Mensal
            </Typography>
            <Chart
              options={{
                chart: {
                  type: 'bar',
                },
                xaxis: {
                  categories: monthlyRevenue.map(item => item.month),
                },
                colors: ['#FFFD82'],
              }}
              series={[
                {
                  name: 'Receita',
                  data: monthlyRevenue.map(item => item.revenue),
                },
              ]}
              type="bar"
              height={300}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Procedimentos mais Realizados
            </Typography>
            <Chart
              options={{
                chart: {
                  type: 'pie',
                },
                labels: proceduresPerformed.map(item => item.name),
                colors: COLORS,
              }}
              series={proceduresPerformed.map(item => item.value)}
              type="pie"
              height={300}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Novos Pacientes por Mês
            </Typography>
            <Chart
              options={{
                chart: {
                  type: 'bar',
                },
                xaxis: {
                  categories: newPatientsPerMonth.map(item => item.month),
                },
                colors: ['#1B998B'],
              }}
              series={[
                {
                  name: 'Pacientes',
                  data: newPatientsPerMonth.map(item => item.patients),
                },
              ]}
              type="bar"
              height={300}
            />
          </Paper>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default Dashboard;
