import React, { Suspense } from 'react';
import { BrowserRouter as Router, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import CustomThemeProvider from './theme/ThemeProvider';
import GlobalStyle from './styles/global';
import Layout from './components/layout/Layout';
import Dashboard from './pages/Dashboard';
import CompanyPage from './features/company/CompanyPage';
import Login from './pages/Login';
import Register from './pages/Register';
import ErrorBoundary from './components/ErrorBoundary';
import UserPage from './features/user/components/UserPage';
import PatientPage from './features/patient/components/PatientPage';
import Service from './features/patient/components/AppointmentPage';
import ModelPage from './features/appointment-model/components/AppointmentModelPage';
import ItemPage from './features/appointment-step/components/model-itemPage';
import AppointmentTimeline from './features/patient/components/AppointmentTimeline';
import ScheduleCalendar from './features/calendar/SchedulerCalendar';
import Loading from './components/common/Loading';
import 'nprogress/nprogress.css';
import MaterialPage from './features/materials/components/MaterialPage';
import ProcedurePage from './features/procedures/components/ProcedurePage';
import PatientRegistration from './pages/PatientRegistration';

const AppContent: React.FC = () => {
  const location = useLocation();
  const isAuthRoute = location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/register') ||
    location.pathname.startsWith('/public-register');

  return isAuthRoute ? (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/public-register/:companyId" element={<PatientRegistration />} />
    </Routes>
  ) : (
    <Layout>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/companies" element={<CompanyPage />} />
        <Route path="/users" element={<UserPage />} />
        <Route path="/patients" element={<PatientPage />} />
        <Route path="/service/:patientId" element={<Service />} />
        <Route path="/models" element={<ModelPage />} />
        <Route path="/item" element={<ItemPage />} />
        <Route path="/timeline/:patientId" element={<AppointmentTimeline />} />
        <Route path="/calendar/" element={<ScheduleCalendar />} />
        <Route path="/materials" element={<MaterialPage />} />
        <Route path="/procedures" element={<ProcedurePage />} />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Layout>
  );
};

const App: React.FC = () => {
  return (
    <CustomThemeProvider>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Router>
            <AppContent />
          </Router>
          <GlobalStyle />
        </Suspense>
      </ErrorBoundary>
    </CustomThemeProvider>
  );
};

export default App;
