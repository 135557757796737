import React from 'react';
import { IconButton, Box, Menu, MenuItem } from '@mui/material';
import { Notifications, AccountCircle, Brightness4, Brightness7, Palette, Language, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useThemeContext } from '../../theme';
import { useTranslation } from 'react-i18next';
import { TwitterPicker } from 'react-color';

interface HeaderProps {
  toggleDrawer: () => void;
  open: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleDrawer, open }) => {
  const { toggleTheme, mode, setPrimaryColor } = useThemeContext();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleColorChange = (color: any) => {
    setPrimaryColor({ main: color.hex });
    setAnchorEl(null);
  };

  const handleColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClick = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    React.startTransition(() => {
      i18n.changeLanguage(lng);
    });
    handleLanguageClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px 16px',
        backgroundColor: 'transparent',
        color: 'inherit',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <IconButton color="inherit" onClick={toggleDrawer} sx={{ marginLeft: '-10px' }}>
        {open ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton color="inherit" onClick={toggleTheme}>
          {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
        <IconButton color="inherit" onClick={handleColorClick}>
          <Palette />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <TwitterPicker
              triangle="hide"
              colors={['#1976d2', '#d32f2f', '#388e3c', '#fbc02d', '#ff9800', '#9c27b0', '#00bcd4']}
              onChangeComplete={handleColorChange}
            />
          </MenuItem>
        </Menu>
        <IconButton color="inherit" onClick={handleLanguageClick}>
          <Language />
        </IconButton>
        <Menu
          anchorEl={languageAnchorEl}
          open={Boolean(languageAnchorEl)}
          onClose={handleLanguageClose}
        >
          <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
          <MenuItem onClick={() => changeLanguage('pt')}>Português</MenuItem>
        </Menu>
        <IconButton color="inherit">
          <Notifications />
        </IconButton>
        <IconButton edge="end" color="inherit">
          <AccountCircle />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Header;
