import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmDeleteDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  itemName: string;
}

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({ open, onClose, itemName }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>{t('confirm_delete')}</DialogTitle>
      <DialogContent>
        <Typography>{t('confirm_delete_message', { item: itemName })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="secondary">
          {t('cancel')}
        </Button>
        <Button onClick={() => onClose(true)} color="error">
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
