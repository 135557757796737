import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { login } from '../services/auth';
import { login as loginAction } from '../store/userSlice';
import AuthLayout from '../components/layout/AuthLayout';
import FormTextField from '../components/common/FormTextField';
import FormButton from '../components/common/FormButton';
import { useToast } from '../contexts/ToastContext';
import { Google as GoogleIcon, Facebook as FacebookIcon } from '@mui/icons-material';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await login({ email, password });
      const token = response.data.access_token;
      console.log('Usuário logado com sucesso:', token);
      dispatch(loginAction(token));
      navigate('/dashboard');
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      showToast('Erro ao fazer login. Verifique suas credenciais.', 'error');
    }
  };

  const handleGoogleLogin = () => {
    // Implementar login com Google
    showToast('Login com Google não implementado.', 'info');
  };

  const handleFacebookLogin = () => {
    // Implementar login com Facebook
    showToast('Login com Facebook não implementado.', 'info');
  };

  return (
    <AuthLayout
      title="Login"
      description="Entre com suas credenciais"
      linkText="Não tem uma conta? Registre-se"
      linkTo="/register"
    >
      <Box component="form" onSubmit={handleLogin} sx={{ width: '100%', mt: 1 }}>
        <FormTextField
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormTextField
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormButton type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>
          Login
        </FormButton>
        <Divider sx={{ my: 2 }}>ou</Divider>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          sx={{ mb: 1 }}
        >
          Login com Google
        </Button>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<FacebookIcon />}
          onClick={handleFacebookLogin}
        >
          Login com Facebook
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default Login;
