import React from 'react';
import { CreateStepDto, UpdateStepDto } from '../services/dto/appointment-step.dto';
import api from '../../../services/api';
import CustomForm from '../../../components/common/CustomForm';
import { useTranslation } from 'react-i18next';

interface AppointmentStepFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  item?: CreateStepDto & { id?: number } | UpdateStepDto | null;
}

const AppointmentStepForm: React.FC<AppointmentStepFormProps> = ({ open, onClose, item }) => {
  const { t } = useTranslation();

  const handleSubmit = async (formData: CreateStepDto | UpdateStepDto) => {
    try {
      if (item && item.id) {
        await api.put(`/api/clinical-steps/${item.id}`, formData);
      } else {
        await api.post('/api/clinical-steps', formData);
      }
      onClose(true);
    } catch (error) {
      console.error(t('error_saving_item'), error);
      onClose(false);
    }
  };

  return (
    <CustomForm
      open={open}
      onClose={onClose}
      title={item ? t('edit_item') : t('add_item')}
      fields={[
        { id: 'value', label: t('value'), type: 'text', required: true },
        { id: 'modelId', label: t('modelId'), type: 'text', required: true },
      ]}
      data={item}
      onSubmit={handleSubmit}
    />
  );
};

export default AppointmentStepForm;
