import React, { useState, useEffect } from 'react';
import { Modal, Paper, Typography, TextField, Button, Autocomplete, Grid, Divider, DialogActions, MenuItem, Select, InputLabel, FormControl, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format, addHours } from 'date-fns';
import api from '../../services/api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

interface AppointmentFormProps {
  open: boolean;
  onClose: () => void;
  event?: any;
  onSave: () => void;
}

const AppointmentForm: React.FC<AppointmentFormProps> = ({ open, onClose, event, onSave }) => {
  const { t } = useTranslation();
  const [patients, setPatients] = useState<{ id: number; name: string }[]>([]);
  const [procedures, setProcedures] = useState<{ id: number; name: string }[]>([]);
  const [isNewPatientFormOpen, setIsNewPatientFormOpen] = useState(false);
  const [appointmentData, setAppointmentData] = useState({
    patientId: '',
    professionalId: '',
    procedureId: '',
    status: 'scheduled',
    date: format(new Date(), "yyyy-MM-dd"),
    startTime: format(new Date(), "HH:mm"),
    endTime: format(addHours(new Date(), 1), "HH:mm"),
    notes: '',
    recurrence: '',
    recurrenceEnd: null as Date | null,
  });

  const [newPatientData, setNewPatientData] = useState({
    name: '',
    birthDate: '',
    gender: '',
    cpf: '',
    contact: '',
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (isNewPatientFormOpen && name in newPatientData) {
      setNewPatientData((prevData) => ({
        ...prevData,
        [name as string]: value as string,
      }));
    } else {
      setAppointmentData((prevData) => ({
        ...prevData,
        [name as string]: value as string,
        ...(name === 'startTime' && {
          endTime: format(addHours(new Date(`${appointmentData.date}T${value as string}`), 1), "HH:mm"),
        }),
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let patientId = appointmentData.patientId;

    if (isNewPatientFormOpen) {
      try {
        const response = await api.post('/api/patients', newPatientData);
        patientId = response.data.id;
      } catch (error) {
        console.error(t('error_saving_patient'), error);
        return; // Exit if saving the new patient fails
      }
    }

    const startDate = new Date(`${appointmentData.date}T${appointmentData.startTime}`);
    const endDate = new Date(`${appointmentData.date}T${appointmentData.endTime}`);

    try {
      const appointmentPayload = {
        patientId: patientId,
        professionalId: appointmentData.professionalId,
        procedureId: appointmentData.procedureId,
        date: startDate,
        endDate: endDate,
        status: appointmentData.status,
        notes: appointmentData.notes,
        recurrence: appointmentData.recurrence,
        recurrenceEnd: appointmentData.recurrenceEnd,
      };

      if (event?.id) {
        await api.put(`/api/appointment-schedules/${event.id}`, appointmentPayload);
      } else {
        await api.post('/api/appointment-schedules', appointmentPayload);
      }
      onSave();
      onClose();
    } catch (error) {
      console.error(t('error_scheduling_appointment'), error);
    }
  };

  const fetchPatients = async () => {
    try {
      const response = await api.get('/api/patients');
      setPatients(response.data);

      if (event) {
        const selectedPatient = response.data.find((patient: { id: number }) => patient.id === event.patientId);
        if (selectedPatient) {
          setAppointmentData((prevData) => ({
            ...prevData,
            patientId: selectedPatient.id.toString(),
          }));
        }
      }
    } catch (error) {
      console.error(t('error_fetching_patients'), error);
    }
  };

  const fetchProcedures = async () => {
    try {
      const response = await api.get('/api/procedures/simple');
      setProcedures(response.data);

      if (event && event.procedureId) {
        const selectedProcedure = response.data.find((procedure: { id: number }) => procedure.id === event.procedureId);
        if (selectedProcedure) {
          setAppointmentData((prevData) => ({
            ...prevData,
            procedureId: selectedProcedure.id.toString(),
          }));
        }
      }
    } catch (error) {
      console.error(t('error_fetching_procedures'), error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchPatients();
      fetchProcedures();

      if (event) {
        setAppointmentData({
          patientId: event.patientId?.toString() || '',
          professionalId: event.professionalId?.toString() || '',
          procedureId: event.procedureId?.toString() || '',
          status: event.status || 'scheduled',
          date: format(new Date(event.start), "yyyy-MM-dd"),
          startTime: format(new Date(event.start), "HH:mm"),
          endTime: format(new Date(event.end), "HH:mm"),
          notes: event.notes || '',
          recurrence: event.recurrence || '',
          recurrenceEnd: event.recurrenceEnd ? new Date(event.recurrenceEnd) : null,
        });
        setIsNewPatientFormOpen(false);
      } else {
        setAppointmentData({
          patientId: '',
          professionalId: '',
          procedureId: '',
          status: 'scheduled',
          date: format(new Date(), "yyyy-MM-dd"),
          startTime: format(new Date(), "HH:mm"),
          endTime: format(addHours(new Date(), 1), "HH:mm"),
          notes: '',
          recurrence: '',
          recurrenceEnd: null,
        });
        setIsNewPatientFormOpen(false);
        setNewPatientData({
          name: '',
          birthDate: '',
          gender: '',
          cpf: '',
          contact: '',
        });
      }
    }
  }, [open, event]);

const statusOptions = [
    { value: 'scheduled', label: t('Agendado'), icon: <ScheduleIcon /> },
    { value: 'confirmed', label: t('Confirmado'), icon: <CheckCircleIcon /> },
    { value: 'awaiting', label: t('Aguardando atendimento'), icon: <HourglassEmptyIcon /> },
    { value: 'no_show', label: t('Não compareceu'), icon: <CancelIcon /> },
    { value: 'completed', label: t('Paciente atendido'), icon: <AssignmentTurnedInIcon /> },
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: 4, width: 500 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {event ? t('Editar Atendimento') : t('Agendar Atendimento')}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!isNewPatientFormOpen && <Autocomplete
                options={patients}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Patient')}
                    fullWidth
                    required
                  />
                )}
                onChange={(event, newValue) => {
                  setAppointmentData((prevData) => ({
                    ...prevData,
                    patientId: newValue?.id.toString() || '',
                  }));
                }}
                value={patients.find((patient) => patient.id.toString() == appointmentData.patientId) || null}
              />}
            </Grid>
            {!appointmentData.patientId && !isNewPatientFormOpen && <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setIsNewPatientFormOpen((prev) => !prev)}
                sx={{ mb: 2 }}
              >
                {t('Novo Paciente')}
              </Button>
            </Grid>}

            {isNewPatientFormOpen && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label={t('Nome')}
                    name="name"
                    fullWidth
                    required
                    value={newPatientData.name}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t('Data de Nascimento')}
                    name="birthDate"
                    type="date"
                    fullWidth
                    required
                    value={newPatientData.birthDate}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t('CPF')}
                    name="cpf"
                    fullWidth
                    required
                    value={newPatientData.cpf}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t('Contato')}
                    name="contact"
                    fullWidth
                    required
                    value={newPatientData.contact}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />

                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    label={t('Sexo')}
                    name="gender"
                    fullWidth
                    required
                    value={newPatientData.gender}
                    onChange={handleInputChange}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  >
                    <MenuItem key="male" value="male">
                      {t('Masculino')}
                    </MenuItem>
                    <MenuItem key="female" value="female">
                      {t('Feminino')}
                    </MenuItem>
                  </TextField>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Autocomplete
                options={procedures}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Procedure')}
                    fullWidth
                    required
                  />
                )}
                onChange={(event, newValue) => {
                  setAppointmentData((prevData) => ({
                    ...prevData,
                    procedureId: newValue?.id.toString() || '',
                  }));
                }}
                value={procedures.find((procedure) => procedure.id.toString() === appointmentData.procedureId) || null}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('Date')}
                type="date"
                name="date"
                fullWidth
                value={appointmentData.date}
                onChange={handleInputChange}
                required
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t('Start Time')}
                type="time"
                name="startTime"
                fullWidth
                value={appointmentData.startTime}
                onChange={handleInputChange}
                required
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t('End Time')}
                type="time"
                name="endTime"
                fullWidth
                value={appointmentData.endTime}
                onChange={handleInputChange}
                required
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>{t('Status')}</InputLabel>
                <Select
                  name="status"
                  value={appointmentData.status}
                  onChange={handleInputChange}
                  renderValue={(value) => {
                    const selectedOption = statusOptions.find(option => option.value === value);
                    return (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {selectedOption?.icon}
                        <Typography sx={{ ml: 1 }}>{selectedOption?.label}</Typography>
                      </Box>
                    );
                  }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {option.icon}
                        <Typography sx={{ ml: 1 }}>{option.label}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Notes')}
                name="notes"
                fullWidth
                multiline
                rows={4}
                value={appointmentData.notes}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
          <DialogActions sx={{ mt: 2 }}>
            <Button onClick={onClose} sx={{ color: 'text.primary' }}>
              {t('cancel')}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {event ? t('save_changes') : t('save')}
            </Button>
          </DialogActions>
        </form>
      </Paper>
    </Modal>
  );
};

export default AppointmentForm;
