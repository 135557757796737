import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import { Add, Edit, Search, FilterList, Delete } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CustomPage from './CustomPage';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  renderSlot?: (item: any) => React.ReactNode; 
}

interface CustomTableProps {
  title: string;
  columns: Column[];
  data: any[];
  onAdd: () => void;
  onEdit: (item: any) => void;
  onDelete: (item: any) => void;
  tabs?: string[];
  tabField?: string;
  filterComponent?: React.ReactNode;
  searchPlaceholder?: string;
  customActions?: (item: any) => React.ReactNode;
}

const CustomTable: React.FC<CustomTableProps> = ({
  title,
  columns,
  data,
  onAdd,
  onEdit,
  onDelete,
  tabs = [],
  tabField = '',
  filterComponent,
  searchPlaceholder = 'Pesquisar...',
  customActions,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filteredData, setFilteredData] = React.useState(data);

  React.useEffect(() => {
    applyFilters();
  }, [tabValue, searchQuery, data]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const applyFilters = () => {
    let filtered = data;

    if (tabs.length > 0 && tabField && tabValue !== 0) {
      filtered = filtered.filter((item) => item[tabField] === tabs[tabValue - 1]);
    }

    if (searchQuery) {
      filtered = filtered.filter((item) =>
        columns.some((column) =>
          String(item[column.id]).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    setFilteredData(filtered);
  };

  return (
    <CustomPage
      title={title}
      breadcrumbLinks={[{ label: t('dashboard'), href: '/dashboard' }]}
      actionButtons={
        <Button sx={{ borderRadius: 2 }} variant="contained" color="primary" onClick={onAdd} startIcon={<Add />}>
          {t('add')}
        </Button>
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder={searchPlaceholder}
          size="small"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.divider,
              },
            },
          }}
        />
        {filterComponent && (
          <Box sx={{ ml: 2 }}>
            <IconButton>
              <FilterList />
            </IconButton>
            {filterComponent}
          </Box>
        )}
      </Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: theme.palette.tableHeader }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ width: '150px' }}>{t('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <TableRow key={row.id} sx={{ '&:hover': { backgroundColor: theme.palette.action.hover }, cursor: 'pointer' }}>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    {column.renderSlot ? column.renderSlot(row) : row[column.id]} 
                  </TableCell>
                ))}
                <TableCell align="center" sx={{ width: '150px', whiteSpace: 'nowrap' }}>
                  {customActions && customActions(row)}
                  <Tooltip title={t('edit')}>
                    <IconButton onClick={() => onEdit(row)}>
                      <Edit color="warning" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('delete')}>
                    <IconButton onClick={() => onDelete(row)}>
                      <Delete color="error" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomPage>
  );
};

export default CustomTable;
