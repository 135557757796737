import React from 'react';
import { CreateUserDto, UpdateUserDto } from '../services/dto/user.dto';
import api from '../../../services/api';
import CustomForm from '../../../components/common/CustomForm';
import { useTranslation } from 'react-i18next';

interface UserFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  user?: CreateUserDto & { id?: number } | UpdateUserDto | null;
}

const UserForm: React.FC<UserFormProps> = ({ open, onClose, user }) => {
  const { t } = useTranslation();

  const handleSubmit = async (formData: CreateUserDto | UpdateUserDto) => {
    if (user && user.id) {
      await api.put(`/api/users/${user.id}`, formData);
    } else {
      await api.post('/api/users', formData);
    }
  };

  return (
    <CustomForm
      open={open}
      onClose={onClose}
      title={user ? t('edit_user') : t('add_user')}
      fields={[
        { id: 'name', label: t('name'), type: 'text', required: true },
        { id: 'email', label: t('email'), type: 'email', required: true },
        { id: 'role', label: t('role'), type: 'text', required: true },
        { id: 'schedulerColor', label: t('schedule_color'), type: 'color', sm: 12, required: true }
      ]}
      data={user}
      onSubmit={handleSubmit}
    />
  );
};

export default UserForm;
