import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import api from '../../../services/api';
import PatientDetailsPage from './PatientDetailsPage';
import AppointmentSteps from './AppointmentSteps';
import SelectAppointmentModelModal from './SelectAppointmentModelModal';

const AppointmentPage: React.FC = () => {
  const { t } = useTranslation();
  const { patientId } = useParams<{ patientId: string }>();
  const [appointmentId, setAppointmentId] = useState<number | null>(null);
  const [appointmentModelId, setAppointmentModelId] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAppointment = async () => {

      try {
        const appointmentResponse = await api.get(`/api/appointments/open/${patientId}`);

        if (appointmentResponse.data?.id) {
          setAppointmentId(appointmentResponse.data.id);
          setAppointmentModelId(appointmentResponse.data.modelId);
        } else {
          setIsModalOpen(true);
        }
        
      } catch (error) {
        console.error(t('error_fetching_appointment'), error);
      }
    };

    fetchAppointment();
  }, [patientId, appointmentModelId]);

  const handleSelectModel = async (modelId: number) => {
    try {
      const newAppointmentResponse = await api.post('/api/appointments', { patientId, modelId });
      setAppointmentId(newAppointmentResponse.data.id);
      setAppointmentModelId(modelId);
      setIsModalOpen(false);
    } catch (error) {
      console.error(t('error_creating_appointment'), error);
    }
  };

  return (
    <PatientDetailsPage>
      {appointmentId && appointmentModelId && (
        <AppointmentSteps
          appointmentId={appointmentId}
          patientId={parseInt(patientId ?? '')}
          appointmentModelId={appointmentModelId}
        />
      )}
      <SelectAppointmentModelModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelect={handleSelectModel}
      />
    </PatientDetailsPage>
  );
};

export default AppointmentPage;
