import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
  }

  #nprogress .bar {
  background: green;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px green, 0 0 5px gren;
  }

  #nprogress .spinner-icon {
    border-top-color: green;
    border-left-color: green;
  }

  
`;

export default GlobalStyle;
