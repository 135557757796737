import React, { createContext, useContext, useMemo, useState } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider, CssBaseline, useMediaQuery, PaletteMode } from '@mui/material';
import PropTypes from 'prop-types';
import { PaletteColorOptions } from '@mui/material/styles';
import { palette } from './palette';
import { overrides } from './overrides';
import { typography } from './typography';

interface ThemeContextProps {
  primaryColor: PaletteColorOptions;
  setPrimaryColor: (color: PaletteColorOptions) => void;
  toggleTheme: () => void;
  mode: PaletteMode;
}

const ThemeContext = createContext<ThemeContextProps>({
  primaryColor: { main: '#00A76F' },
  setPrimaryColor: () => {},
  toggleTheme: () => {},
  mode: 'light',
});

export const useThemeContext = () => useContext(ThemeContext);

const CustomThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<PaletteMode>(prefersDarkMode ? 'dark' : 'light');
  const [primaryColor, setPrimaryColor] = useState<PaletteColorOptions>({ main: '#00A76F' });

  const theme = useMemo(
    () =>
      createTheme({
        palette: { ...palette(mode), mode, primary: primaryColor },
        typography,
        shape: { borderRadius: 8 },
        components: {
          ...overrides(createTheme({ palette: { ...palette(mode), mode, primary: primaryColor } })),
        },
        sidebar: {
          backgroundColor: mode === 'dark' ? '#1C252E' : '#14191E',
        },
      }),
    [mode, primaryColor]
  );

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ primaryColor, setPrimaryColor, toggleTheme, mode }}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

CustomThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default CustomThemeProvider;
