import api from '../../../services/api';

import { CreateMaterialDto, UpdateMaterialDto } from './dto/material.dto';  


export const fetchMaterials = async () => {
  const response = await api.get('/api/materials');
  return response.data;
};

export const fetchSimpleMaterials = async () => {
  const response = await api.get('/api/materials/simple');
  return response.data;
};
export const fetchMaterialById = async (id: number) => {
  const response = await api.get(`/api/materials/${id}`);
  return response.data;
};

export const createMaterial = async (createMaterialDto: CreateMaterialDto) => {
  const response = await api.post('/api/materials', createMaterialDto);
  return response.data;
};

export const updateMaterial = async (id: number, updateMaterialDto: UpdateMaterialDto) => {
  const response = await api.put(`/api/materials/${id}`, updateMaterialDto);
  return response.data;
};

export const deleteMaterial = async (id: number) => {
  await api.delete(`/api/materials/${id}`);
};
