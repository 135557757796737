import React from 'react';
import api from '../../services/api';
import CustomForm from '../../components/common/CustomForm';
import { useTranslation } from 'react-i18next';

interface Company {
  id?: number;
  name: string;
  address: string;
}

interface CompanyFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  company?: Company | null;
}

const CompanyForm: React.FC<CompanyFormProps> = ({ open, onClose, company }) => {
  const { t } = useTranslation();

  const handleSubmit = async (formData: Company) => {
    if (company && company.id) {
      await api.put(`/api/companies/${company.id}`, formData);
    } else {
      await api.post('/api/companies', formData);
    }
  };

  return (
    <CustomForm
      open={open}
      onClose={onClose}
      title={company ? t('edit_company') : t('add_company')}
      fields={[
        { id: 'name', label: t('name'), type: 'text', required: true },
        { id: 'address', label: t('address'), type: 'text', required: true },
      ]}
      data={company}
      onSubmit={handleSubmit}
    />
  );
};

export default CompanyForm;
