import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import AppointmentModelForm from './AppointmentModelForm';
import CustomTable from '../../../components/common/CustomTable';

interface AppointmentModel {
  id: number;
  name: string;
}

const AppointmentModelPage: React.FC = () => {
  const { t } = useTranslation();
  const [models, setModels] = useState<AppointmentModel[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState<AppointmentModel | null>(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await api.get('/api/appointment-models');
        setModels(response.data);
      } catch (error) {
        console.error(t('error_fetching_models'), error);
      }
    };

    fetchModels();
  }, [t]);

  const handleEdit = (model: AppointmentModel) => {
    setSelectedModel(model);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setSelectedModel(null);
    setOpenModal(true);
  };

  const handleCloseModal = async (updated: boolean = false) => {
    setOpenModal(false);
    setSelectedModel(null);
    if (updated) {
      try {
        const response = await api.get('/api/appointment-models');
        setModels(response.data);
      } catch (error) {
        console.error(t('error_fetching_models'), error);
      }
    }
  };

  return (
    <>
      <CustomTable
        title={t('models')}
        columns={[
          { id: 'name', label: t('name') },
        ]}
        data={models}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleEdit}
        searchPlaceholder={t('search_models')}
      />
      <AppointmentModelForm open={openModal} onClose={handleCloseModal} model={selectedModel} />
    </>
  );
};

export default AppointmentModelPage;
