import api from '../../../services/api';
import { CreateProcedureDto, UpdateProcedureDto } from './dto/procedures.dto';

export const fetchProcedures = async () => {
  const response = await api.get('/api/procedures');
  return response.data;
};

export const fetchProcedureById = async (id: number) => {
  const response = await api.get(`/api/procedures/${id}`);
  return response.data;
};

export const createProcedure = async (createProcedureDto: CreateProcedureDto) => {
  const response = await api.post('/api/procedures', createProcedureDto);
  return response.data;
};

export const updateProcedure = async (id: number, updateProcedureDto: UpdateProcedureDto) => {
  const response = await api.put(`/api/procedures/${id}`, updateProcedureDto);
  return response.data;
};

export const deleteProcedure = async (id: number) => {
  await api.delete(`/api/procedures/${id}`);
};
