import React, { useState } from 'react';
import {
  TextField, Button, Grid, Box, Paper, Typography, Stepper, Step, StepLabel,
  MenuItem, useMediaQuery, useTheme, Alert
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMask } from '@react-input/mask';
import axios from 'axios';
import api from '../services/api';
import { useToast } from '../contexts/ToastContext';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useParams } from 'react-router-dom';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Nome é obrigatório')
    .matches(/^\S+(\s+\S+)+$/, 'O nome deve incluir pelo menos um sobrenome'),
  birthDate: yup
    .string()
    .required('Data de nascimento é obrigatória')
    .test('age', 'A idade deve estar entre 0 e 120 anos', function (value) {
      const birthDate = new Date(value as string);
      const age = new Date().getFullYear() - birthDate.getFullYear();
      return age >= 0 && age <= 120;
    }),
  gender: yup.string().required('Gênero é obrigatório'),
  contact: yup
    .string()
    .required('Contato é obrigatório')
    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'Formato de telefone inválido'),
  email: yup
    .string()
    .required('Email é obrigatório')
    .email('Formato de email inválido'),
});


type PatientFormValues = {
  name: string;
  birthDate: string;
  gender: string;
  email: string;
  contact: string;
  address?: {
    cep?: string;
    line1?: string;
    number?: string;
    line2?: string;
    city?: string;
    state?: string;
    country?: string;
  };
};

const steps = ['Informações Gerais', 'Endereço', 'Confirmação'];

const PatientRegistration: React.FC = () => {
  const { companyId } = useParams()
  const [activeStep, setActiveStep] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showToast } = useToast(); // Hook para exibir o Toast
  const { control, handleSubmit, setValue, formState: { errors }, getValues } = useForm<PatientFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const phoneMask = useMask({ mask: '(__) _____-____', replacement: '_' });
  const cepMask = useMask({ mask: '_____-___', replacement: '_' });

  const handleCepChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const cep = e.target.value.replace(/\D/g, '');
    setValue('address.cep', e.target.value);

    if (cep.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { logradouro, localidade, uf } = response.data;
        setValue('address.line1', logradouro || '');
        setValue('address.city', localidade || '');
        setValue('address.state', uf || '');
        setValue('address.country', 'Brasil');
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    }
  };

  type Field = {
    name: string;
    label: string;
    type: 'text' | 'date' | 'select';
    required: boolean;
    xs: number;
    options?: { value: string; label: string }[];
    mask?: React.MutableRefObject<HTMLInputElement | null>;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
  };

  const fieldsByStep: Field[][] = [
    [
      { name: 'name', label: 'Nome', type: 'text', required: true, xs: 12 },
      { name: 'birthDate', label: 'Data de Nascimento', type: 'date', required: true, xs: 6 },
      {
        name: 'gender', label: 'Sexo', type: 'select', options: [
          { value: 'male', label: 'Masculino' },
          { value: 'female', label: 'Feminino' }
        ], required: true, xs: 6
      },
      { name: 'contact', label: 'Telefone', type: 'text', required: true, mask: phoneMask, xs: 12 },
      { name: 'email', label: 'Email', type: 'text', required: true, xs: 12 },
    ],
    [
      { name: 'address.cep', label: 'CEP', type: 'text', required: true, mask: cepMask, xs: 12, onChange: handleCepChange },
      { name: 'address.line1', label: 'Rua', type: 'text', required: true, xs: 12 },
      { name: 'address.line2', label: 'Complemento', type: 'text', required: false, xs: 12 },
      { name: 'address.number', label: 'Número', type: 'text', required: true, xs: 6 },
      { name: 'address.city', label: 'Cidade', type: 'text', required: true, xs: 6, readOnly: true },
      { name: 'address.state', label: 'Estado', type: 'text', required: true, xs: 6, readOnly: true },
      { name: 'address.country', label: 'País', type: 'text', required: true, xs: 6, readOnly: true },
    ]
  ];

  const onSubmit = async (data: PatientFormValues) => {
    if (activeStep === steps.length - 1) {
      try {
        if (!data.address?.cep) {
          delete data.address
        }
        await api.post('/api/patients/public-register', { ...data, companyId });
        setShowSuccessMessage(true);
        showToast('Paciente cadastrado com sucesso!', 'success');
      } catch (error) {
        console.error('Erro ao cadastrar paciente:', error);
        showToast('Erro ao cadastrar paciente', 'error');
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderFields = (fields: any[]) => {
    if (!fields || fields.length === 0) return null;

    return fields.map((field) => (
      <Grid item xs={field.xs || 12} key={field.name}>
        <Controller
          name={field.name}
          control={control}
          render={({ field: controllerField }) => (
            <TextField
              {...controllerField}
              inputRef={field.mask || undefined}
              label={field.label}
              select={field.type === 'select'}
              type={field.type}
              fullWidth
              InputProps={{
                readOnly: field.readOnly || false,
              }}
              InputLabelProps={{
                shrink: field.type === 'date' || field.readOnly ? true : undefined,
              }}
              error={!!errors[field.name as keyof PatientFormValues]}
              helperText={errors[field.name as keyof PatientFormValues]?.message}
              onChange={field.onChange || controllerField.onChange}
            >
              {field.options && field.options.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
    ));
  };

  const renderConfirmation = () => {
    const values = getValues();

    const addressFields: string[] = [];

    return (
      <Grid container spacing={0.2}>
        {fieldsByStep.flat().map((field) => {
          const fieldName = field.name;
          const nameParts = fieldName.split('.');
          let value: any = values;

          nameParts.forEach((part) => {
            value = value ? value[part as keyof typeof value] : null;
          });

          let displayValue: string;

          if (field.type === 'date' && value) {
            try {
              displayValue = format(new Date(value as string | Date), 'dd/MM/yyyy', { locale: ptBR });
            } catch {
              displayValue = String(value);
            }
          } else if (typeof value === 'object' && value !== null) {
            displayValue = Object.values(value).filter(Boolean).join(', ');
          } else {
            displayValue = value ? String(value) : "";
          }

          if (field.name.startsWith('address')) {
            addressFields.push(`${displayValue}`);
            return null;
          }

          return (
            <Grid item xs={12} key={field.name}>
              <Box sx={{ mb: 0.2 }}>
                <Typography variant="body2" color="textSecondary">
                  {field.label}:
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {displayValue}
                </Typography>
              </Box>
            </Grid>
          );
        })}
        {addressFields.length > 0 && (
          <Grid item xs={12}>
            <Box sx={{ mb: 0 }}>
              <Typography variant="body2" color="textSecondary">
                Endereço:
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {addressFields.join(', ')}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };


  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: isMobile ? 0 : 2,
        backgroundColor: '#f5f5f5',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          width: '100%',
          maxWidth: 600,
          minHeight: isMobile ? '100vh' : 'auto',
          boxSizing: 'border-box',
          display: showSuccessMessage ? 'flex' : 'block',
          justifyContent: showSuccessMessage ? 'center' : undefined,
          alignItems: showSuccessMessage ? 'center' : undefined,
        }}
      >
        {showSuccessMessage ? (
          <Typography variant="h4" component="div" sx={{ textAlign: 'center', mb: 4 }}>
            Cadastro realizado com sucesso!
          </Typography>
        ) : (
          <>
            <Typography variant="h5" component="div" sx={{ textAlign: 'center', mb: 4 }}>
              Cadastro de Paciente
            </Typography>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {renderFields(fieldsByStep[activeStep])}
              </Grid>
              {activeStep === 2 && (
                <>
                  <Box mt={3}>
                    <Typography align="center" variant="h6" gutterBottom>
                      Confirme as Informações
                    </Typography>
                    <Paper variant="outlined" sx={{ p: 2 }}>
                      {renderConfirmation()}
                    </Paper>
                  </Box>
                </>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                >
                  Voltar
                </Button>
                <Button type="submit" variant="contained">
                  {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                </Button>
              </Box>
            </form>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default PatientRegistration;
