import React, { useState } from 'react';
import { Box, Button, Divider } from '@mui/material';
import { Google as GoogleIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import AuthLayout from '../components/layout/AuthLayout';
import FormTextField from '../components/common/FormTextField';
import FormButton from '../components/common/FormButton';
import { useToast } from '../contexts/ToastContext';
import { register } from '../services/auth';

const Register: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [companyId, setCompanyId] = useState(1);
  const { showToast } = useToast();

  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await register({ name, email, password, role, companyId });
      console.log('Usuário registrado com sucesso:', response.data);
    } catch (error) {
      console.error('Erro ao registrar usuário:', error);
      showToast('Erro ao registrar usuário. Verifique seus dados.', 'error');
    }
  };

  const handleGoogleRegister = () => {
    // Implementar registro com Google
    showToast('Registro com Google não implementado.', 'info');
  };

  const handleFacebookRegister = () => {
    // Implementar registro com Facebook
    showToast('Registro com Facebook não implementado.', 'info');
  };

  return (
    <AuthLayout
      title="Registrar"
      description="Crie sua conta"
      linkText="Já tem uma conta? Faça login"
      linkTo="/login"
    >
      <Box component="form" onSubmit={handleRegister} sx={{ width: '100%', mt: 1 }}>
        <FormTextField
          id="name"
          label="Nome"
          name="name"
          autoComplete="name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormTextField
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormTextField
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormTextField
          id="role"
          label="Role"
          name="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormButton type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>
          Registrar
        </FormButton>
        <Divider sx={{ my: 2 }}>ou</Divider>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleRegister}
          sx={{ mb: 1 }}
        >
          Registrar com Google
        </Button>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<FacebookIcon />}
          onClick={handleFacebookRegister}
        >
          Registrar com Facebook
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default Register;
