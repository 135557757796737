import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import CompanyForm from './CompanyForm';
import CustomTable from '../../components/common/CustomTable';
import { useTranslation } from 'react-i18next';

interface Company {
  id: number;
  name: string;
  address: string;
}

const CompanyPage: React.FC = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await api.get('/api/companies');
        setCompanies(response.data);
      } catch (error) {
        console.error(t('error_fetching_companies'), error);
      }
    };

    fetchCompanies();
  }, [t]);

  const handleEdit = (company: Company) => {
    setSelectedCompany(company);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setSelectedCompany(null);
    setOpenModal(true);
  };

  const handleCloseModal = async (updated: boolean = false) => {
    setOpenModal(false);
    setSelectedCompany(null);
    if (updated) {
      try {
        const response = await api.get('/api/companies');
        setCompanies(response.data);
      } catch (error) {
        console.error(t('error_fetching_companies'), error);
      }
    }
  };

  return (
    <>
      <CustomTable
        title={t('companies')}
        columns={[
          { id: 'name', label: t('name') },
          { id: 'address', label: t('address') },
        ]}
        data={companies}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleEdit}
        searchPlaceholder={t('search_companies')}
      />
      <CompanyForm open={openModal} onClose={handleCloseModal} company={selectedCompany} />
    </>
  );
};

export default CompanyPage;
