import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';

interface SelectAppointmentModelModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (modelId: number) => void;
}

const SelectAppointmentModelModal: React.FC<SelectAppointmentModelModalProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [models, setModels] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await api.get('/api/appointment-models/simple');
        const fetchedModels = response.data;

        // Se houver apenas um modelo, selecionar automaticamente
        if (fetchedModels.length === 1) {
          onSelect(fetchedModels[0].id);
        } else {
          setModels(fetchedModels);
        }
      } catch (error) {
        console.error(t('error_fetching_models'), error);
      }
    };

    if (open) {
      fetchModels();
    }
  }, [open, t, onSelect]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={2}>
          {models.map((model) => (
            <Grid item xs={12} sm={6} key={model.id}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => onSelect(model.id)}
              >
                {model.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SelectAppointmentModelModal;
