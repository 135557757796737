import React from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import CustomForm, { Field } from '../../../components/common/CustomForm';
import { CreatePatientDto, UpdatePatientDto } from '../services/dto/patient.dto';

interface PatientFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  patient?: CreatePatientDto & { id?: number } | UpdatePatientDto | null;
}

const PatientForm: React.FC<PatientFormProps> = ({ open, onClose, patient }) => {
  const { t } = useTranslation();

  const handleSubmit = async (formData: CreatePatientDto | UpdatePatientDto) => {
    if (patient && patient.id) {
      await api.put(`/api/patients/${patient.id}`, formData);
    } else {
      await api.post('/api/patients', formData);
    }
    onClose(true);
  };

  const fields: Field[] = [
    { id: 'name', label: t('name'), type: 'text', required: true, tab: 'general' },
    { id: 'birthDate', label: t('birthDate'), type: 'date', required: true, tab: 'general', sm: 6 },
    { id: 'gender', label: t('sex'), type: 'select', options: [{ label: t('male'), value: 'male' }, { label: t('female'), value: 'female' }], required: true, tab: 'general', sm: 6 },
    { id: 'genderOptional', label: t('gender'), type: 'select', options: [
      { label: t('cisgender'), value: 'cisgender' },
      { label: t('transgender'), value: 'transgender' },
      { label: t('nonBinary'), value: 'nonBinary' },
      { label: t('agender'), value: 'agender' }
    ], tab: 'general', sm: 6 },
    { id: 'cpf', label: t('cpf'), type: 'text', required: true, mask: [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/], tab: 'general', sm: 6 },
    { id: 'rg', label: t('rg'), type: 'text', tab: 'general', sm: 6 },
    { id: 'email', label: t('email'), type: 'text', tab: 'general', sm: 6 },
    { id: 'contact', label: t('contact'), type: 'text', format: 'phone', required: true, mask: ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], tab: 'general', sm: 12 },
    { id: 'notes', label: t('notes'), type: 'textarea', tab: 'general' },
    { id: 'referralSource', label: t('referralSource'), type: 'select', options: [
      { label: t('medicalReferral'), value: 'medicalReferral' },
      { label: t('medicalGuide'), value: 'medicalGuide' },
      { label: t('hospital'), value: 'hospital' },
      { label: t('patientIndication'), value: 'patientIndication' },
      { label: t('internet'), value: 'internet' },
      { label: t('other'), value: 'other' }
    ], tab: 'general' },
    { id: 'clinicalHistory', label: t('clinicalHistory'), type: 'text', tab: 'medicalHistory', sm: 12 },
    { id: 'surgicalHistory', label: t('surgicalHistory'), type: 'text', tab: 'medicalHistory', sm: 12 },
    { id: 'familyHistory', label: t('familyHistory'), type: 'text', tab: 'medicalHistory', sm: 12 },
    { id: 'habits', label: t('habits'), type: 'text', tab: 'medicalHistory', sm: 12 },
    { id: 'allergies', label: t('allergies'), type: 'text', tab: 'medicalHistory', sm: 12 },
    { id: 'medications', label: t('medications'), type: 'text', tab: 'medicalHistory', sm: 12 },
    { id: 'address.cep', label: t('cep'), type: 'text', mask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], tab: 'address', sm: 6 },
    { id: 'address.line1', label: t('line1'), type: 'text', tab: 'address', sm: 6 },
    { id: 'address.number', label: t('number'), type: 'text', tab: 'address', sm: 6 },
    { id: 'address.line2', label: t('line2'), type: 'text', tab: 'address', sm: 6 },
    { id: 'address.city', label: t('city'), type: 'text', tab: 'address', sm: 6 },
    { id: 'address.state', label: t('state'), type: 'text', tab: 'address', sm: 6 },
    { id: 'address.country', label: t('country'), type: 'text', tab: 'address', sm: 6 },
  ];

  return (
    <CustomForm
      open={open}
      onClose={onClose}
      title={patient ? t('edit_patient') : t('add_patient')}
      fields={fields}
      data={patient}
      onSubmit={handleSubmit}
    />
  );
};

export default PatientForm;
