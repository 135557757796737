import React, { useState } from 'react';
import { Typography, Grid, Box, Avatar, Divider, Tooltip, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CustomForm from '../../../components/common/CustomForm';
import api from '../../../services/api';

interface PatientSummaryProps {
  patient: any;
  onUpdate: (updated: boolean) => void;
}

const renderSummaryItem = (label: string, value: string) => (
  <Grid item xs={12} sm={6}>
    <Typography variant="body2" fontWeight="bold">
      <strong>{label}:</strong>
    </Typography>
    <Tooltip title={value}>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  </Grid>
);

const PatientSummary: React.FC<PatientSummaryProps> = ({ patient, onUpdate }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = async (updated = false) => {
    setOpenModal(false);
    onUpdate(updated);
  };

  return (
    <Grid item xs={12} md={6}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{t('patientSummary')}</Typography>
        <IconButton onClick={handleOpenModal} sx={{ ml: 1 }}>
          <EditIcon />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box display="flex" alignItems="center" mb={1}>
        <Avatar
          alt={patient?.name || 'Avatar'}
          src="https://via.placeholder.com/150"
          sx={{ width: 80, height: 80, mr: 2 }}
        />
        <Box>
          <Grid container spacing={1}>
            {renderSummaryItem(t('name'), patient?.name || '')}
            {renderSummaryItem(t('birthDate'), patient?.birthDate ? new Date(patient.birthDate).toLocaleDateString() : '')}
            {renderSummaryItem(t('gender'), t(patient?.gender) || '')}
            {renderSummaryItem(t('email'), patient?.email || '')}
            {renderSummaryItem(t('contact'), patient?.contact || '')}
            {renderSummaryItem(t('notes'), patient?.notes || '')}
          </Grid>
        </Box>
      </Box>

      <CustomForm
        open={openModal}
        onClose={handleCloseModal}
        title={t('editPatientSummary')}
        fields={[
          { id: 'name', label: t('name'), type: 'text', required: true, sm: 6 },
          { id: 'birthDate', label: t('birthDate'), type: 'date', required: true, sm: 6 },
          {
            id: 'gender', label: t('sex'), type: 'select', options: [
              { label: t('male'), value: 'male' },
              { label: t('female'), value: 'female' }
            ], required: true, sm: 6
          },
          {
            id: 'genderOptional', label: t('gender'), type: 'select', options: [
              { label: t('cisgender'), value: 'cisgender' },
              { label: t('transgender'), value: 'transgender' },
              { label: t('nonBinary'), value: 'nonBinary' },
              { label: t('agender'), value: 'agender' }
            ], sm: 6
          },
          { id: 'cpf', label: t('cpf'), type: 'text', required: true, mask: [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/], sm: 6 },
          { id: 'rg', label: t('rg'), type: 'text', sm: 6 },
          { id: 'email', label: t('email'), type: 'text', sm: 6 },
          { id: 'contact', label: t('contact'), type: 'text', required: true, mask: ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], sm: 12 },
          { id: 'notes', label: t('notes'), type: 'textarea', sm: 12 },
        ]}
        data={{
          name: patient?.name || '',
          birthDate: patient?.birthDate ? new Date(patient.birthDate).toISOString().split('T')[0] : '',
          gender: patient?.gender || '',
          genderOptional: patient?.genderOptional || '',
          cpf: patient?.cpf || '',
          rg: patient?.rg || '',
          email: patient?.email || '',
          contact: patient?.contact || '',
          notes: patient?.notes || '',
        }}
        onSubmit={async (data) => {
          const updatedPatient = { ...patient, ...data };
          try {
            await api.put(`/api/patients/${patient.id}`, updatedPatient);
            handleCloseModal(true);
          } catch (error) {
            console.error(t('error_updating_patient'), error);
          }
        }}
      />
    </Grid>
  );
};

export default PatientSummary;
