import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import ProcedureForm from './ProcedureForm';
import CustomTable from '../../../components/common/CustomTable';
import { CreateProcedureDto, UpdateProcedureDto } from '../services/dto/procedures.dto';
import { Circle, Palette } from '@mui/icons-material';

interface Procedure {
  id: number;
  name: string;
  description: string;
  duration?: number;
  cost?: number;
  materialIds: number[];
  color?: string;  // Campo para a cor
}

const ProcedurePage: React.FC = () => {
  const { t } = useTranslation();
  const [procedures, setProcedures] = useState<Procedure[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState<Procedure | null>(null);

  useEffect(() => {
    const fetchProcedures = async () => {
      try {
        const response = await api.get('/api/procedures');
        setProcedures(response.data);
      } catch (error) {
        console.error(t('error_fetching_procedures'), error);
      }
    };

    fetchProcedures();
  }, [t]);

  const handleEdit = (procedure: Procedure) => {
    setSelectedProcedure(procedure);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setSelectedProcedure(null);
    setOpenModal(true);
  };

  const handleDelete = async (id: number) => {
    try {
      await api.delete(`/api/procedures/${id}`);
      setProcedures(procedures.filter(procedure => procedure.id !== id));
    } catch (error) {
      console.error(t('error_deleting_procedure'), error);
    }
  };

  const handleCloseModal = async (updated: boolean = false) => {
    setOpenModal(false);
    setSelectedProcedure(null);
    if (updated) {
      try {
        const response = await api.get('/api/procedures');
        setProcedures(response.data);
      } catch (error) {
        console.error(t('error_fetching_procedures'), error);
      }
    }
  };

  return (
    <>
      <CustomTable
        title={t('procedures')}
        columns={[
          {
            id: 'color', 
            label: t('color'),
            renderSlot: (procedure) => (
              <Palette style={{ color: procedure.color }} />
            )
          },
          { id: 'name', label: t('name') },
          { id: 'description', label: t('description') },
          { id: 'duration', label: t('duration') },
          { id: 'cost', label: t('cost') }
        ]}
        data={procedures}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        searchPlaceholder={t('search_procedures')}
      />
      <ProcedureForm open={openModal} onClose={handleCloseModal} procedure={selectedProcedure} />
    </>
  );
};

export default ProcedurePage;
