import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, IconButton, Grid, Divider, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add as AddIcon, Delete as DeleteIcon, DragIndicator as DragIndicatorIcon } from '@mui/icons-material';
import api from '../../../services/api';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface CreateClinicalStepDto {
  title: string;
  type: string;
  order: number;
}

interface CreateAppointmentModelDto {
  name: string;
  clinicalSteps?: CreateClinicalStepDto[];
}

interface UpdateAppointmentModelDto extends CreateAppointmentModelDto {
  id: number;
}

interface AppointmentModelFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  model?: CreateAppointmentModelDto & { id?: number } | UpdateAppointmentModelDto | null;
}

const AppointmentModelForm: React.FC<AppointmentModelFormProps> = ({ open, onClose, model }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<CreateAppointmentModelDto>({ name: '', clinicalSteps: [] });
  const [steps, setSteps] = useState<CreateClinicalStepDto[]>([]);

  useEffect(() => {
    if (model) {
      setFormData(model);
      setSteps(model.clinicalSteps || []);
    } else {
      setFormData({ name: '', clinicalSteps: [] });
      setSteps([]);
    }
  }, [model, open]); // Adicionado 'open' para resetar os dados ao abrir o formulário

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddStep = () => {
    setSteps([...steps, { title: '', type: 'text', order: steps.length + 1 }]);
  };

  const handleRemoveStep = (index: number) => {
    setSteps(steps.filter((_, i) => i !== index).map((step, i) => ({ ...step, order: i + 1 })));
  };

  const handleStepChange = (index: number, field: keyof CreateClinicalStepDto, value: any) => {
    const updatedSteps = [...steps];
    updatedSteps[index] = { ...updatedSteps[index], [field]: value };
    setSteps(updatedSteps);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedSteps = Array.from(steps);
    const [removed] = reorderedSteps.splice(result.source.index, 1);
    reorderedSteps.splice(result.destination.index, 0, removed);

    setSteps(reorderedSteps.map((step, index) => ({ ...step, order: index + 1 })));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const submitData = { ...formData, clinicalSteps: steps };
    try {
      if (model && model.id) {
        await api.put(`/api/appointment-models/${model.id}`, submitData);
      } else {
        await api.post('/api/appointment-models', submitData);
      }
      onClose(true);
    } catch (error) {
      console.error('Error saving data:', error);
      onClose(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth>
      <DialogTitle>{model ? t('edit_model') : t('add_model')}</DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label={t('name')}
                value={formData.name}
                onChange={handleFormChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t('steps')}</Typography>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="steps">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {steps.map((step, index) => (
                        <Draggable key={index} draggableId={`step-${index}`} index={index}>
                          {(provided) => (
                            <Paper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ padding: '16px', marginBottom: '8px', display: 'flex', alignItems: 'center', ...provided.draggableProps.style }}
                            >
                              <DragIndicatorIcon style={{ marginRight: '8px' }} />
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={5}>
                                  <TextField
                                    fullWidth
                                    label={t('title')}
                                    value={step.title}
                                    onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                                    required
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <TextField
                                    fullWidth
                                    select
                                    label={t('type')}
                                    value={step.type}
                                    onChange={(e) => handleStepChange(index, 'type', e.target.value)}
                                    required
                                    variant="outlined"
                                  >
                                    <MenuItem value="text">{t('text')}</MenuItem>
                                    <MenuItem value="select">{t('select')}</MenuItem>
                                    <MenuItem value="date">{t('date')}</MenuItem>
                                    <MenuItem value="number">{t('number')}</MenuItem>
                                    <MenuItem value="file">{t('file')}</MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <IconButton onClick={() => handleRemoveStep(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Paper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddStep} style={{ marginTop: '1em' }}>
                {t('add_step')}
              </Button>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={() => onClose(false)}>{t('cancel')}</Button>
            <Button type="submit" color="primary" variant="contained">
              {model ? t('save_changes') : t('save')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentModelForm;
