import axios from 'axios';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '../store';
import { logout } from '../store/userSlice';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://hmanage.com.br',
});

nprogress.configure({ showSpinner: false });

api.interceptors.request.use((config) => {
  nprogress.start();
  const state = store.getState();
  const token = state.user.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    nprogress.done();
    return response;
  },
  (error) => {
    nprogress.done();
    if (error.response && error.response.status === 401) {
      const currentPath = window.location.pathname;
      store.dispatch(logout());
      if (currentPath !== '/login') {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default api;
