import React from 'react';
import { CreateProcedureDto, UpdateProcedureDto } from '../services/dto/procedures.dto';
import { createProcedure, updateProcedure } from '../services/procedureApi';
import CustomForm from '../../../components/common/CustomForm';
import { useTranslation } from 'react-i18next';
import { fetchSimpleMaterials } from '../../materials/services/materialApi';

interface ProcedureFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  procedure?: CreateProcedureDto & { id?: number } | UpdateProcedureDto | null;
}

const ProcedureForm: React.FC<ProcedureFormProps> = ({ open, onClose, procedure }) => {
  const { t } = useTranslation();
  const [materials, setMaterials] = React.useState<{ id: number; name: string }[]>([]);

  React.useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const response = await fetchSimpleMaterials();
        setMaterials(response.map((material: any) => ({ id: material.id, name: material.name })));
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    };

    if (open) {
      fetchMaterials();
    }
  }, [open]);

  const handleSubmit = async (formData: any) => {
    if (procedure && procedure.id) {
      await updateProcedure(procedure.id, formData);
    } else {
      await createProcedure(formData);
    }
  };

  return (
    <CustomForm
      open={open}
      onClose={onClose}
      title={procedure ? t('edit_procedure') : t('add_procedure')}
      fields={[
        { id: 'name', label: t('name'), type: 'text', required: true, sm: 12 },
        { id: 'description', label: t('description'), type: 'text', required: true, sm: 12 },
        { id: 'duration', label: t('duration'), type: 'number', format: 'minutes', sm: 6 },
        { id: 'cost', label: t('cost'), type: 'number', format: 'currency', sm: 6 },
        {
          id: 'materialIds',
          label: t('materials'),
          type: 'multiselect',
          options: materials.map(material => ({ label: material.name, value: material.id.toString() })),
          required: true,
          sm: 12,
        },
        { id: 'color', label: t('color'), type: 'color', sm: 12 }
      ]}
      data={{
        ...procedure,
        materialIds: procedure?.materialIds ? procedure.materialIds.map(id => id.toString()) : [],
      }}
      onSubmit={handleSubmit}
    />
  );
};

export default ProcedureForm;
