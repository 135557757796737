import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import UserForm from './UserForm';
import CustomTable from '../../../components/common/CustomTable';
import { Palette } from '@mui/icons-material';

interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  companyId: number;
}

const UserPage: React.FC = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error(t('error_fetching_users'), error);
      }
    };

    fetchUsers();
  }, [t]);

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setSelectedUser(null);
    setOpenModal(true);
  };

  const handleCloseModal = async (updated: boolean = false) => {
    setOpenModal(false);
    setSelectedUser(null);
    if (updated) {
      try {
        const response = await api.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error(t('error_fetching_users'), error);
      }
    }
  };

  return (
    <>
      <CustomTable
        title={t('users')}
        columns={[
          {
            id: 'color', 
            label: t('schedule_color'),
            renderSlot: (user) => (
              <Palette style={{ color: user.schedulerColor }} />
            )
          },
          { id: 'name', label: t('name') },
          { id: 'email', label: t('email') },
          { id: 'role', label: t('role') }
        ]}
        data={users}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleEdit}
        searchPlaceholder={t('search_users')}
      />
      <UserForm open={openModal} onClose={handleCloseModal} user={selectedUser} />
    </>
  );
};

export default UserPage;
