import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  Tab,
  Divider,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  InputAdornment,
  Select,
  Checkbox,
  ListItemText,
  Autocomplete,
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';

export interface Field {
  id: string;
  label: string;
  type: 'text' | 'number' | 'email' | 'password' | 'select' | 'multiselect' | 'autocomplete' | 'masked' | 'date' | 'datetime-local' | 'color' | 'textarea';
  required?: boolean;
  options?: { label: string, value: string }[];
  mask?: (string | RegExp)[];
  tab?: string;
  sm?: number;
  order?: number;
  width?: number;
  multiple?: boolean;
  format?: 'currency' | 'minutes' | 'percentage' | 'phone' | 'datetime'; // Adiciona mais formatos
}

interface CustomFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  title: string;
  fields: Field[];
  data?: any;
  onSubmit: (data: any) => Promise<void>;
}

interface FormData {
  [key: string]: string | number | string[] | undefined;
}

const CustomForm: React.FC<CustomFormProps> = ({ open, onClose, title, fields, data, onSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [formData, setFormData] = useState<any>({});
  const [tabIndex, setTabIndex] = useState<string>(fields.some(field => field.tab) ? 'general' : '');

  const tabs = Array.from(new Set(fields.filter(field => field.tab).map(field => field.tab || 'general')));

  useEffect(() => {
    if (data) {
      setFormData(data);
    } else {
      const initialData = fields.reduce((acc, field) => ({ ...acc, [field.id]: '' }), {});
      setFormData(initialData);
    }
  }, [data, fields]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
    const { name, value } = event.target;
    setFormData((prevFormData: FormData) => ({
      ...prevFormData,
      [name]: value as string[],
    }));
  };

  const handleAutocompleteChange = (event: any, value: any, field: Field) => {
    setFormData({
      ...formData,
      [field.id]: value,
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await onSubmit(formData);
      onClose(true);
    } catch (error) {
      console.error('Erro ao salvar dados:', error);
      onClose(false);
    }
  };

  const renderTextField = (field: Field) => {
    const { id, label, required, sm, type, format } = field;

    return (
      <Grid item xs={12} sm={sm || 12} key={id}>
        <TextField
          fullWidth
          name={id}
          label={label}
          type={type}
          value={formData[id]}
          onChange={handleChange}
          required={required}
          variant="outlined"
          InputProps={{
            startAdornment: format === 'currency' ? <InputAdornment position="start">R$</InputAdornment> : undefined,
            endAdornment: format === 'minutes' ? <InputAdornment position="end">min</InputAdornment> : undefined,
            inputProps: format === 'percentage' ? { inputMode: 'decimal', pattern: '[0-9]*' } : undefined,
          }}
          InputLabelProps={type === 'date' || type === 'datetime-local' ? { shrink: true } : undefined} // Ajuste para campos de data
        />
      </Grid>
    );
  };

  const renderSelectField = (field: Field) => {
    const { id, label, required, options, sm } = field;
    return (
      <Grid item xs={12} sm={sm || 12} key={id}>
        <TextField
          fullWidth
          select
          name={id}
          label={label}
          value={formData[id]}
          onChange={handleChange}
          required={required}
          variant="outlined"
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  };

  const renderMultiSelectField = (field: Field) => {
    const { id, label, required, options, sm } = field;
    const value = Array.isArray(formData[id]) ? formData[id] : [];

    return (
      <Grid item xs={12} sm={sm || 12} key={id}>
        <FormControl fullWidth required={required} variant="outlined">
          <InputLabel>{label}</InputLabel>
          <Select
            multiple
            name={id}
            label={label}
            value={value}
            onChange={handleMultiSelectChange}
            renderValue={(selected) =>
              (selected as string[]).map(value => options?.find(option => option.value === value)?.label).join(', ')
            }
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={value.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderAutocompleteField = (field: Field) => {
    const { id, label, options, sm, multiple } = field;
    return (
      <Grid item xs={12} sm={sm || 12} key={id}>
        <Autocomplete
          multiple={multiple}
          options={options || []}
          getOptionLabel={(option) => option.label}
          value={formData[id] || (multiple ? [] : null)}
          onChange={(event, value) => handleAutocompleteChange(event, value, field)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={label}
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  };

  const renderDateField = (field: Field) => {
    const { id, label, required, sm } = field;
    return (
      <Grid item xs={12} sm={sm || 12} key={id}>
        <TextField
          fullWidth
          type="date"
          name={id}
          label={label}
          value={formData[id]}
          onChange={handleChange}
          required={required}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    );
  };

  const renderMaskedField = (field: Field) => {
    const { id, label, required, mask, sm } = field;
    return (
      <Grid item xs={12} sm={sm || 12} key={id}>
        {mask && (
          <MaskedInput
            mask={mask}
            value={formData[id]}
            onChange={handleChange}
            render={(ref, props) => (
              <TextField
                {...props}
                fullWidth
                name={id}
                label={label}
                inputRef={ref}
                required={required}
                variant="outlined"
              />
            )}
          />
        )}
      </Grid>
    );
  };

  const renderField = (field: Field) => {
    switch (field.type) {
      case 'select':
        return renderSelectField(field);
      case 'multiselect':
        return renderMultiSelectField(field);
      case 'autocomplete':
        return renderAutocompleteField(field);
      case 'masked':
        return renderMaskedField(field);
      default:
        return renderTextField(field);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {tabs.length > 1 ? (
            <TabContext value={tabIndex}>
              <TabList onChange={handleTabChange}>
                {tabs.map((tab) => (
                  <Tab key={tab} label={t(tab)} value={tab} />
                ))}
              </TabList>
              {tabs.map((tab) => (
                <TabPanel key={tab} value={tab} sx={{ p: 0, pt: 2, pb: 2 }}>
                  <Grid container spacing={1.5}>
                    {fields.filter(field => field.tab === tab).map(renderField)}
                  </Grid>
                </TabPanel>
              ))}
            </TabContext>
          ) : (
            <Grid container spacing={1.5}>
              {fields.map(renderField)}
            </Grid>
          )}

          <DialogActions sx={{ pb: 0, pt: 2 }}>
            <Button onClick={() => onClose(false)} sx={{ color: theme.palette.text.primary }}>
              {t('cancel')}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {data ? t('save_changes') : t('save')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CustomForm;
