import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import PatientForm from './PatientForm';
import CustomTable from '../../../components/common/CustomTable';
import ConfirmDeleteDialog from '../../../components/common/ConfirmDeleteDialog';
import { IconButton, Tooltip, MenuItem, ListItemIcon, Typography } from '@mui/material';
import { DirectionsWalk, PlayCircleFilled, Timeline } from '@mui/icons-material';

interface Patient {
  id: number;
  name: string;
  cpf: string;
  contact: string;
  clinicalHistory?: string;
  surgicalHistory?: string;
  familyHistory?: string;
  habits?: string;
  allergies?: string;
  medications?: string;
}

const PatientPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [patients, setPatients] = useState<Patient[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState<Patient | null>(null);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await api.get('/api/patients');
        setPatients(response.data);
      } catch (error) {
        console.error(t('error_fetching_patients'), error);
      }
    };

    fetchPatients();
  }, [t]);

  const handleEdit = (patient: Patient) => {
    setSelectedPatient(patient);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setSelectedPatient(null);
    setOpenModal(true);
  };

  const handleCloseModal = async (updated: boolean = false) => {
    setOpenModal(false);
    setSelectedPatient(null);
    if (updated) {
      try {
        const response = await api.get('/api/patients');
        setPatients(response.data);
      } catch (error) {
        console.error(t('error_fetching_patients'), error);
      }
    }
  };

  const handleDelete = async (patient: Patient) => {
    setPatientToDelete(patient);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = async (confirmed: boolean) => {
    setConfirmDeleteOpen(false);
    if (confirmed && patientToDelete) {
      try {
        await api.delete(`/api/patients/${patientToDelete.id}`);
        setPatients((prevPatients) => prevPatients.filter((p) => p.id !== patientToDelete.id));
      } catch (error) {
        console.error(t('error_deleting_patient'), error);
      }
    }
    setPatientToDelete(null);
  };

  const handleServiceRedirect = (patientId: number) => {
    navigate(`/service/${patientId}`);
  };

  const handleAppointmentRedirect = (patientId: number) => {
    navigate(`/timeline/${patientId}`);
  };

  return (
    <>
      <CustomTable
        title={t('patients')}
        columns={[
          { id: 'name', label: t('name') },
          { id: 'cpf', label: t('cpf') },
          { id: 'contact', label: t('contact') },
        ]}
        data={patients}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        customActions={(patient: Patient) => (
          <>
            <Tooltip title='Atendimentos'>
              <IconButton onClick={() => handleAppointmentRedirect(patient.id)}>
                <Timeline />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('service')}>
              <IconButton onClick={() => handleServiceRedirect(patient.id)}>
                <PlayCircleFilled color="primary" />
              </IconButton>
            </Tooltip>
          </>
        )}
        searchPlaceholder={t('search_patients')}
      />
      <PatientForm open={openModal} onClose={handleCloseModal} patient={selectedPatient} />
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={handleConfirmDeleteClose}
        itemName={patientToDelete ? patientToDelete.name : ''}
      />
    </>
  );
};

export default PatientPage;
