import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createMaterial, updateMaterial } from '../services/materialApi';
import { CreateMaterialDto, UpdateMaterialDto } from '../services/dto/material.dto';

interface MaterialFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  material?: CreateMaterialDto & { id?: number } | UpdateMaterialDto | null;
}

const MaterialForm: React.FC<MaterialFormProps> = ({ open, onClose, material }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<CreateMaterialDto>({
    name: '',
    description: '',
    quantityInStock: 0,
    reorderPoint: 0,
    unitPrice: undefined,
    supplier: '',
    category: '',
  });

  useEffect(() => {
    if (material) {
      setFormData(material as CreateMaterialDto);
    } else {
      setFormData({
        name: '',
        description: '',
        quantityInStock: 0,
        reorderPoint: 0,
        unitPrice: undefined,
        supplier: '',
        category: '',
      });
    }
  }, [material, open]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === 'quantityInStock' || name === 'reorderPoint' || name === 'unitPrice' ? parseFloat(value) : value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (material && (material as any).id) {
        await updateMaterial((material as any).id, formData);
      } else {
        await createMaterial(formData);
      }
      onClose(true);
    } catch (error) {
      console.error('Error saving data:', error);
      onClose(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth>
      <DialogTitle>{material ? t('edit_material') : t('add_material')}</DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label={t('name')}
                value={formData.name}
                onChange={handleFormChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="description"
                label={t('description')}
                value={formData.description}
                onChange={handleFormChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="quantityInStock"
                label={t('quantity_in_stock')}
                value={formData.quantityInStock}
                onChange={handleFormChange}
                required
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="reorderPoint"
                label={t('reorder_point')}
                value={formData.reorderPoint}
                onChange={handleFormChange}
                required
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="unitPrice"
                label={t('unit_price')}
                value={formData.unitPrice !== undefined ? formData.unitPrice : ''}
                onChange={handleFormChange}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="supplier"
                label={t('supplier')}
                value={formData.supplier}
                onChange={handleFormChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="category"
                label={t('category')}
                value={formData.category}
                onChange={handleFormChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={() => onClose(false)}>{t('cancel')}</Button>
            <Button type="submit" color="primary" variant="contained">
              {material ? t('save_changes') : t('save')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default MaterialForm;
