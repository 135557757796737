import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer, Views, View } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Button, Typography, useTheme, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward, AccessTime } from '@mui/icons-material'; // Importa o ícone de relógio
import api from '../../services/api';
import CustomPage from '../../components/common/CustomPage';
import AppointmentForm from './AppointmentForm';

import { darken, lighten } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const localizer = dateFnsLocalizer({
  format: (date: any, formatStr: string, options: any) => format(date, formatStr, { locale: ptBR }),
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }),
  getDay,
  locales: { 'pt-BR': ptBR },
});

const ScheduleCalendar: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [events, setEvents] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState<null | any>(null);
  const [view, setView] = useState<View>(Views.WEEK);
  const [legend, setLegend] = useState<{ name: string; color: string }[]>([]);

  const statusOptions = [
    { value: 'scheduled', label: t('Agendado'), icon: <ScheduleIcon /> },
    { value: 'confirmed', label: t('Confirmado'), icon: <CheckCircleIcon /> },
    { value: 'awaiting', label: t('Aguardando atendimento'), icon: <HourglassEmptyIcon /> },
    { value: 'no_show', label: t('Não compareceu'), icon: <CancelIcon /> },
    { value: 'completed', label: t('Paciente atendido'), icon: <AssignmentTurnedInIcon /> },
  ];

  const handleOpenModal = (date?: Date, event?: any) => {
    if (event) {
      setEditingEvent({ ...event });
    } else if (date) {
      setEditingEvent({
        title: '',
        start: date,
        end: new Date(date.getTime() + 60 * 60 * 1000),
        notes: '',
        professionalId: '',
        procedureId: '',
        status: 'scheduled',
        recurrence: '',
        recurrenceEnd: null,
      });
    } else {
      setEditingEvent(null);
    }
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditingEvent(null); // Clear editing event on close
  };

  const fetchAppointments = async () => {
    try {
      const response = await api.get('/api/appointment-schedules/calendar');
      const data = response.data
        .map((appointment: any) => ({
          id: appointment.id,
          title: appointment.title,
          start: new Date(appointment.start),
          end: new Date(appointment.end),
          allDay: false,
          notes: appointment.notes || '',
          professionalId: appointment.professionalId,
          professionalName: appointment.professionalName,
          patientId: appointment.patientId,
          procedureId: appointment.procedureId,
          status: appointment.status,
          recurrence: appointment.recurrence,
          schedulerColor: appointment.schedulerColor || theme.palette.primary.light, // Ensure schedulerColor is defined
          recurrenceEnd: appointment.recurrenceEnd ? new Date(appointment.recurrenceEnd) : null,
        }))
        .filter((event: any) => {
          const eventStartHour = event.start.getHours();
          const eventEndHour = event.end.getHours();
          return eventStartHour >= 8 && eventEndHour <= 21; // Ensure events are within allowed time range
        });
      setEvents(data);

      // Create legend based on unique colors and professionals
      const uniqueLegend = data.reduce((acc: any, event: any) => {
        if (!acc.some((item: any) => item.color === event.schedulerColor)) {
          acc.push({
            name: event.professionalName, // Assuming this is the professional's name
            color: event.schedulerColor,
          });
        }
        return acc;
      }, []);
      setLegend(uniqueLegend);

    } catch (error) {
      console.error(t('error_fetching_appointments'), error);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [t]);

  function disablePastDates(start: Date): boolean {
    return start < new Date();
  }

  const EventComponent = ({ event }: { event: any }) => (
    <Box sx={{ position: 'relative', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography
        variant="body2"
        sx={{
          textAlign: 'left',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        title={event.title}
      >
        {event.title}
      </Typography>
      {statusOptions.find((option) => option.value === event.status)?.icon}
      {/* <AccessTime sx={{ fontSize: 16, color: 'white' }} /> */}
    </Box>
  );

  return (
    <CustomPage
      title={t('Agendar Atendimento')}
      breadcrumbLinks={[{ label: 'Dashboard', href: '/dashboard' }]}
      actionButtons={<Button variant="contained" color="primary" onClick={() => handleOpenModal()}>{t('Agendar Atendimento')}</Button>}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Calendar
          localizer={localizer}
          culture="pt-BR"
          events={events}
          startAccessor="start"
          endAccessor="end"
          view={view}
          onView={(newView) => setView(newView)}
          views={['month', 'week', 'work_week', 'day', 'agenda']}
          style={{ height: '75vh', width: '100%' }}
          selectable
          onSelectSlot={(slotInfo) => !disablePastDates(slotInfo.start) && handleOpenModal(slotInfo.start)}
          onSelectEvent={(event) => handleOpenModal(undefined, event)}
          eventPropGetter={(event) => {
            const isPastEvent = event.start < new Date();
            return {
              style: {
                borderRadius: '8px',
                padding: '5px',
                border: `2px solid ${isPastEvent ? lighten(event.schedulerColor || theme.palette.primary.light, 0.3) : event.schedulerColor ? darken(event.schedulerColor, 0.3) : theme.palette.primary.dark}`, // Escurece a borda do evento
                fontSize: '0.80em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                backgroundColor: isPastEvent ? lighten(event.schedulerColor || theme.palette.primary.light, 0.5) : event.schedulerColor || theme.palette.primary.light, // Clareia a cor de fundo para eventos passados
              },
            };
          }}
          components={{
            event: EventComponent,
            toolbar: (props) => (
              <div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <IconButton onClick={() => props.onNavigate('PREV')}>
                      <ArrowBack />
                    </IconButton>
                    <Button onClick={() => props.onNavigate('TODAY')} sx={{ ml: 1 }}>
                      {t('Hoje')}
                    </Button>
                    <IconButton onClick={() => props.onNavigate('NEXT')}>
                      <ArrowForward />
                    </IconButton>
                  </Box>
                  <Typography variant="h6" sx={{ textAlign: 'center', flexGrow: 1 }}>
                    {props.label}
                  </Typography>
                  <Box>
                    <Button onClick={() => props.onView('month')} sx={{ mr: 1 }}>
                      {t('Mês')}
                    </Button>
                    <Button onClick={() => props.onView('week')} sx={{ mr: 1 }}>
                      {t('Semana')}
                    </Button>
                    <Button onClick={() => props.onView('day')} sx={{ mr: 1 }}>
                      {t('Dia')}
                    </Button>
                    <Button onClick={() => props.onView('agenda')}>
                      {t('Lista')}
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', gap: 2, mb: 0.2 }}>
                  {legend.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: 16, height: 16, backgroundColor: item.color, borderRadius: '50%', border: `2px solid ${darken(item.color, 0.3)}` }} />
                      <Typography variant="body2">{item.name}</Typography>
                    </Box>
                  ))}
                </Box>
              </div>
            ),
          }}
          min={new Date(1970, 1, 1, 8, 0, 0)}
          max={new Date(1970, 1, 1, 21, 0, 0)}
        />
      </Box>

      <AppointmentForm
        open={modalOpen}
        onClose={handleCloseModal}
        event={editingEvent}
        onSave={fetchAppointments}
      />
    </CustomPage>
  );
};

export default ScheduleCalendar;
