import React from 'react';
import { Box, Grid, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
  description: string;
  linkText: string;
  linkTo: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, title, description, linkText, linkTo }) => {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/assets/background.png)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => t.palette.grey[50],
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Box}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ p: 4, position: 'relative' }}
      >
        <Box sx={{ position: 'absolute', top: 16, right: 16, display: 'flex', alignItems: 'center' }}>
          <img src="/logo.svg" alt="Logo" style={{ height: '40px', marginRight: '8px' }} />
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            My Clinical
          </Typography>
        </Box>
        <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 4, textAlign: 'center' }}>
            {description}
          </Typography>
          {children}
          <Box mt={3}>
            <Typography variant="body2">
              {linkText}{' '}
              <MuiLink component={Link} to='/login' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                {linkText}
              </MuiLink>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
