import { PaletteMode } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const primary = {
  lighter: '#C8FAD6',
  light: '#5BE49B',
  main: '#00A76F',
  dark: '#007867',
  darker: '#004B50',
  contrastText: '#FFFFFF',
};

export const secondary = {
  lighter: grey[200],
  light: grey[400],
  main: grey[600],
  dark: grey[800],
  darker: grey[900],
  contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

export const success = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#FFFFFF',
};

export const warning = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const basePalette = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

export const palette = (mode: PaletteMode) => ({
  ...basePalette,
  mode,
  ...(mode === 'dark'
    ? {
        text: {
          primary: '#FFFFFF',
          secondary: grey[500],
          disabled: grey[600],
        },
        background: {
          paper: '#1C252E', // Cor dos cards
          default: '#141A21', // Cor de fundo
          neutral: '#1C252E',
        },
        tableHeader: '#28323D', // Cor do header das tabelas
        action: {
          ...basePalette.action,
          active: grey[600],
        },
      }
    : {
        text: {
          primary: grey[800],
          secondary: grey[600],
          disabled: grey[500],
        },
        background: {
          paper: '#FFFFFF',
          default: grey[100],
          neutral: grey[200],
        },
        tableHeader: grey[300],
        action: {
          ...basePalette.action,
          active: grey[600],
        },
      }),
});
