import React from 'react';
import { Box, Typography, Breadcrumbs, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

interface CustomPageProps {
  title: string;
  breadcrumbLinks: { label: string, href: string }[];
  actionButtons?: React.ReactNode;
  children: React.ReactNode;
  showPaper?: boolean;
}

const CustomPage: React.FC<CustomPageProps> = ({
  title,
  breadcrumbLinks,
  actionButtons,
  children,
  showPaper = true
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>{title}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Breadcrumbs aria-label="breadcrumb" separator="›" sx={{ '& .MuiBreadcrumbs-separator': { color: theme.palette.text.primary } }}>
          {breadcrumbLinks.map((link, index) => (
            <RouterLink
              key={index}
              to={link.href}
              style={{
                color: theme.palette.text.primary,
                textDecoration: 'none',
                fontWeight: 500
              }}
            >
              {link.label}
            </RouterLink>
          ))}
          <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 500 }}>{title}</Typography>
        </Breadcrumbs>
        <Box>
          {actionButtons}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {showPaper ? (
          <Paper sx={{ p: 2, pt: 1, boxShadow: 2, backgroundColor: theme.palette.background.paper, borderRadius: 3 }}>
            {children}
          </Paper>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default CustomPage;
