import React, { useState, useEffect } from 'react';
import { Grid, Divider, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomPage from '../../../components/common/CustomPage';
import api from '../../../services/api';
import PatientSummary from './PatientSummary';
import MedicalHistory from './MedicalHistory';

export interface FormValues {
  clinicalHistory: string;
  surgicalHistory: string;
  familyHistory: string;
  habits: string;
  allergies: string;
  medications: string;
}

interface PatientDetailsPageProps {
  children?: React.ReactNode;
}

const PatientDetailsPage: React.FC<PatientDetailsPageProps> = ({ children }) => {
  const { t } = useTranslation();
  const { patientId } = useParams<{ patientId: string }>();
  const [formValues, setFormValues] = useState<FormValues>({
    clinicalHistory: '',
    surgicalHistory: '',
    familyHistory: '',
    habits: '',
    allergies: '',
    medications: '',
  });
  const [patient, setPatient] = useState<any>(null);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const response = await api.get(`/api/patients/${patientId}`);
        const patientData = response.data;
        setPatient(patientData);
        setFormValues({
          clinicalHistory: patientData.clinicalHistory || '',
          surgicalHistory: patientData.surgicalHistory || '',
          familyHistory: patientData.familyHistory || '',
          habits: patientData.habits || '',
          allergies: patientData.allergies || '',
          medications: patientData.medications || '',
        });
      } catch (error) {
        console.error(t('error_fetching_patient'), error);
      }
    };

    fetchPatient();
  }, [patientId, t]);

  const handleUpdate = (updated: boolean) => {
    if (updated) {
      // Re-fetch patient data if needed
    }
  };

  return (
    <CustomPage
      title={t('appointment')}
      breadcrumbLinks={[{ label: 'Pacientes', href: '/patients' }]}
    >
      <Grid container spacing={1}>
        <PatientSummary patient={patient} onUpdate={handleUpdate} />
        <MedicalHistory patient={patient} formValues={formValues} onUpdate={handleUpdate} />
      </Grid>

      <Divider sx={{ my: 2 }} />
      {children}
    </CustomPage>
  );
};

export default PatientDetailsPage;
